import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
/* Layouts */
const Layout1 = () => import('../layouts/Layout1.vue')

const AuthLayout = () => import('../layouts/AuthLayouts/AuthLayout')
/* Dashboards View */
const Dashboard = () => import('../views/Dashboards/Dashboard1.vue')
const Portfolio = () => import('../views/Dashboards/Dashboard2.vue')
const Reports = () => import('../views/Dashboards/Dashboard3')
const Orders = () => import('../views/Dashboards/Dashboard4')

/* Authentic View */
const SignIn1 = () => import('../views/AuthPages/Default/SignIn1')
const SignUp1 = () => import('../views/AuthPages/Default/SignUp1')
const Callback = () => import('../views/AuthPages/Default/Callback')

Vue.use(VueRouter)

const authChildRoutes = (prop) => [
  {
    path: 'sign-in1',
    name: prop + '.sign-in1',
    meta: { auth: true },
    component: SignIn1
  },
  {
    path: 'sign-up1',
    name: prop + '.sign-up1',
    meta: { auth: true },
    component: SignUp1
  }
]

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Layout1,
    meta: { auth: true },
    children: [
      {
        path: 'index',
        name: 'dashboard.home-1',
        meta: { auth: true, name: 'Home 1' },
        component: Dashboard
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'portfolio',
    component: Layout1,
    meta: { auth: true },
    children: [
      {
        path: 'portfolio',
        name: 'dashboard.portfolio',
        meta: { auth: true, name: 'Home 2' },
        component: Portfolio
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'reports',
    component: Layout1,
    meta: { auth: true },
    children: [
      {
        path: 'reports',
        name: 'dashboard.reports',
        meta: { auth: true, name: 'Home 3' },
        component: Reports
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'orders',
    component: Layout1,
    meta: { auth: true },
    children: [
      {
        path: 'orders',
        name: 'dashboard.orders',
        meta: { auth: true, name: 'Home 4' },
        component: Orders
      }
    ]
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout,
    meta: { auth: true },
    children: authChildRoutes('auth1')
  },
  {
    path: '/callback',
    name: 'callback',
    meta: { auth: false },
    component: Callback
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // const publicPages = ['/auth/sign-in1', '/auth/sign-up1', '/dark/auth/sign-in1', '/dark/auth/sign-up1']
  // if (publicPages.includes(to.path)) {
  //   localStorage.removeItem('user')
  //   localStorage.removeItem('access_token')
  // }
  if (to.path === '/') {
    next({ path: '/auth/sign-in1' })
  } else {
    next()
  }
  // const authRequired = !publicPages.includes(to.path)
  // const loggedIn = localStorage.getItem('user')
  const accToken = store.state.Setting.logToken
  // if (to.meta.auth) {
  //   if (authRequired && accToken === '') {
  //     return next('/auth/sign-in1')
  //   } else if (to.name === 'dashboard1' || to.name === 'dashboard2') {
  //     return next()
  //   }
  // }
  // next()
  // console.log('acct', accToken)
  if (!accToken && to.path !== '/auth/sign-in1') {
    if (to.path === '/resetPassword') {
      next()
    } else {
      next({ path: '/auth/sign-in1' })
    }
  } else {
    if (to.path === '/auth/sign-in1') {
      next()
    } else {
      if (to.path === '/undefined') {
        next({ path: '/404' })
      } else {
        next()
      }
    }
  }
})

export default router
