import '@babel/polyfill'
import Vue from 'vue'
import 'mutationobserver-shim'
import './Utils/fliter'
import './config/firebase'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import './registerServiceWorker'
import AlgoliaComponents from 'vue-instantsearch'
import i18n from './i18n'
import './directives'
global.Raphael = Raphael

Vue.use(AlgoliaComponents)

Vue.config.productionTip = false

// router.beforeEach(async (to, from, next) => {
//   if (to.path === '/') {
//     next({ path: '/auth/sign-in1' })
//   } else {
//     next()
//   }
//   if (to.path === '/auth/sign-in1') {
//     next()
//   }
//   // console.log('cc', store.state.Setting.logToken)
//   const accToken = store.state.log_token
//   if (!accToken && to.path !== '/auth/sign-in1') {
//     if (to.path === '/resetPassword') {
//       next()
//     } else {
//       next({ path: '/auth/sign-in1' })
//     }
//   } else {
//     if (to.path === '/auth/sign-in1') {
//       next()
//     } else {
//       if (to.path === '/undefined') {
//         next({ path: '/404' })
//       } else {
//         next()
//       }
//     }
//   }
// })

const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
